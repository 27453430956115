exports.onRouteUpdate = () => {
  const metaViewport = document.querySelector('meta[name="viewport"]');
  if (metaViewport) {
    metaViewport.parentNode.removeChild(metaViewport);
  }

  const head = document.getElementsByTagName("head")[0];
  const meta = document.createElement("meta");
  meta.setAttribute("name", "viewport");
  meta.setAttribute(
    "content",
    "width=device-width, initial-scale=1, shrink-to-fit=no"
  );
  head.appendChild(meta);
};
