exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-impacto-articulos-index-js": () => import("./../../../src/pages/impacto/articulos/index.js" /* webpackChunkName: "component---src-pages-impacto-articulos-index-js" */),
  "component---src-pages-impacto-autor-[id]-js": () => import("./../../../src/pages/impacto/autor/[id].js" /* webpackChunkName: "component---src-pages-impacto-autor-[id]-js" */),
  "component---src-pages-impacto-autores-index-js": () => import("./../../../src/pages/impacto/autores/index.js" /* webpackChunkName: "component---src-pages-impacto-autores-index-js" */),
  "component---src-pages-impacto-columna-[id]-js": () => import("./../../../src/pages/impacto/columna/[id].js" /* webpackChunkName: "component---src-pages-impacto-columna-[id]-js" */),
  "component---src-pages-impacto-columnas-index-js": () => import("./../../../src/pages/impacto/columnas/index.js" /* webpackChunkName: "component---src-pages-impacto-columnas-index-js" */),
  "component---src-pages-impacto-index-js": () => import("./../../../src/pages/impacto/index.js" /* webpackChunkName: "component---src-pages-impacto-index-js" */),
  "component---src-pages-impacto-medios-index-js": () => import("./../../../src/pages/impacto/medios/index.js" /* webpackChunkName: "component---src-pages-impacto-medios-index-js" */),
  "component---src-pages-impacto-publicacion-[id]-js": () => import("./../../../src/pages/impacto/publicacion/[id].js" /* webpackChunkName: "component---src-pages-impacto-publicacion-[id]-js" */),
  "component---src-pages-impacto-publicaciones-index-js": () => import("./../../../src/pages/impacto/publicaciones/index.js" /* webpackChunkName: "component---src-pages-impacto-publicaciones-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metodologias-index-js": () => import("./../../../src/pages/metodologias/index.js" /* webpackChunkName: "component---src-pages-metodologias-index-js" */),
  "component---src-pages-proyectos-breca-contencion-index-js": () => import("./../../../src/pages/proyectos/breca/contencion/index.js" /* webpackChunkName: "component---src-pages-proyectos-breca-contencion-index-js" */),
  "component---src-pages-proyectos-breca-desenrollate-index-js": () => import("./../../../src/pages/proyectos/breca/desenrollate/index.js" /* webpackChunkName: "component---src-pages-proyectos-breca-desenrollate-index-js" */),
  "component---src-pages-proyectos-breca-estudiantes-index-js": () => import("./../../../src/pages/proyectos/breca/estudiantes/index.js" /* webpackChunkName: "component---src-pages-proyectos-breca-estudiantes-index-js" */),
  "component---src-pages-proyectos-breca-index-js": () => import("./../../../src/pages/proyectos/breca/index.js" /* webpackChunkName: "component---src-pages-proyectos-breca-index-js" */),
  "component---src-pages-proyectos-breca-reco-index-js": () => import("./../../../src/pages/proyectos/breca/reco/index.js" /* webpackChunkName: "component---src-pages-proyectos-breca-reco-index-js" */),
  "component---src-pages-proyectos-breit-index-js": () => import("./../../../src/pages/proyectos/breit/index.js" /* webpackChunkName: "component---src-pages-proyectos-breit-index-js" */),
  "component---src-pages-proyectos-index-js": () => import("./../../../src/pages/proyectos/index.js" /* webpackChunkName: "component---src-pages-proyectos-index-js" */),
  "component---src-pages-proyectos-pais-aprendizaje-index-js": () => import("./../../../src/pages/proyectos/pais/aprendizaje/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-aprendizaje-index-js" */),
  "component---src-pages-proyectos-pais-arcoiris-index-js": () => import("./../../../src/pages/proyectos/pais/arcoiris/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-arcoiris-index-js" */),
  "component---src-pages-proyectos-pais-becatalento-index-js": () => import("./../../../src/pages/proyectos/pais/becatalento/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-becatalento-index-js" */),
  "component---src-pages-proyectos-pais-becatalento-puno-index-js": () => import("./../../../src/pages/proyectos/pais/becatalento/puno/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-becatalento-puno-index-js" */),
  "component---src-pages-proyectos-pais-becatalento-sobre-index-js": () => import("./../../../src/pages/proyectos/pais/becatalento/sobre/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-becatalento-sobre-index-js" */),
  "component---src-pages-proyectos-pais-becatalento-tacna-index-js": () => import("./../../../src/pages/proyectos/pais/becatalento/tacna/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-becatalento-tacna-index-js" */),
  "component---src-pages-proyectos-pais-crecer-index-js": () => import("./../../../src/pages/proyectos/pais/crecer/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-crecer-index-js" */),
  "component---src-pages-proyectos-pais-index-js": () => import("./../../../src/pages/proyectos/pais/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-index-js" */),
  "component---src-pages-proyectos-pais-tasa-index-js": () => import("./../../../src/pages/proyectos/pais/tasa/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-tasa-index-js" */),
  "component---src-pages-proyectos-pais-volar-index-js": () => import("./../../../src/pages/proyectos/pais/volar/index.js" /* webpackChunkName: "component---src-pages-proyectos-pais-volar-index-js" */),
  "component---src-pages-quienesomos-empresas-index-js": () => import("./../../../src/pages/quienesomos/empresas/index.js" /* webpackChunkName: "component---src-pages-quienesomos-empresas-index-js" */),
  "component---src-pages-quienesomos-equipo-index-js": () => import("./../../../src/pages/quienesomos/equipo/index.js" /* webpackChunkName: "component---src-pages-quienesomos-equipo-index-js" */),
  "component---src-pages-quienesomos-historia-index-js": () => import("./../../../src/pages/quienesomos/historia/index.js" /* webpackChunkName: "component---src-pages-quienesomos-historia-index-js" */),
  "component---src-pages-quienesomos-index-js": () => import("./../../../src/pages/quienesomos/index.js" /* webpackChunkName: "component---src-pages-quienesomos-index-js" */),
  "component---src-pages-quienesomos-proposito-index-js": () => import("./../../../src/pages/quienesomos/proposito/index.js" /* webpackChunkName: "component---src-pages-quienesomos-proposito-index-js" */)
}

